import React from 'react'
import './Header.css'
import HeaderLink from './headerLink.component'
import Headroom from 'react-headroom'

const toggleBurger = () => {
	document.getElementById('navMenu').classList.toggle('is-active')
	document.getElementById('burger').classList.toggle('is-active')
}

const closeBurger = () => {
	document.getElementById('navMenu').classList.remove('is-active')
	document.getElementById('burger').classList.remove('is-active')
}

const Header = () => {
	const linkList = [
		{
			title: 'Capability list',
			href: 'capabilitySection',
		},
		{
			title: 'About',
			href: 'aboutSection',
		},
		{
			title: 'News',
			href: 'feedSection',
		},
		{
			title: 'Employees',
			href: 'employeeSection',
		},
		{
			title: 'Customers',
			href: 'customerSection',
		},
	]

	const renderLinks = () =>
		linkList.map(link => <HeaderLink toggleBurger={toggleBurger} link={link} key={link.title} />)

	return (
		<React.Fragment>
			<Headroom style={{ zIndex: 5 }} onUnpin={closeBurger}>
				<header className="headerComponent">
					<nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
						<div className="container">
							<div className="navbar-brand">
								<a className="navbar-item" href="#">
									<img
										className="logo"
										src={require('../../assets/arct-maint-logo.jpeg')}
										alt="company logo"
									/>
								</a>
								<span id="burger" className="navbar-burger burger" onClick={toggleBurger}>
									<span />
									<span />
									<span />
								</span>
							</div>
							<div id="navMenu" className="navbar-menu">
								<div className="navbar-end">{renderLinks()}</div>
							</div>
						</div>
					</nav>
				</header>
			</Headroom>
		</React.Fragment>
	)
}

export default Header
