import React from 'react'
import './Customers.css'
import { CollapsibleContainer } from '../../components'

const Customers = ({ customers }) => {
	const renderCustomers = () => {
		return customers.map(customer => {
			const { name, logo, link } = customer.node.data
			return (
				<div className="column is-one-third-desktop is-half-tablet is-full-mobile" key={customer.node.id}>
					<a href={link.url} target={link.target} aria-label={name.text} className="customerContent">
						<img src={logo.url} alt={logo.alt} />
					</a>
				</div>
			)
		})
	}

	return (
		<section name="customerSection">
			<div className="customersComponent">
				<CollapsibleContainer title="Our customers">{renderCustomers()}</CollapsibleContainer>
			</div>
		</section>
	)
}

export default Customers
