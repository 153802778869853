import React from 'react'
import './Employee.css'
import { Email, Phone } from 'styled-icons/material'

const Employee = ({ employee }) => {
	return (
		<div className="employeeComponent">
			<div className="employeeName">{employee.name}</div>
			<div className="employeeTitle">{employee.title}</div>
			{employee.email && (
				<a href={`mailto:${employee.email}`} className="employeeEmail">
					<Email />
					{employee.email}
				</a>
			)}
			{employee.phone && (
				<a href={`tel:+354${employee.phone}`} className="employeePhone">
					<Phone />
					{employee.phone}
				</a>
			)}
		</div>
	)
}

export default Employee
