import React from 'react'
import Collapsible from 'react-collapsible'
import { CollapsibleTrigger } from '..'

const CollapsibleContainer = ({ children, title, expanded = true }) => {
	return (
		<React.Fragment>
			<div className="container">
				<div className="columns">
					<div className="column is-full">
						<div className="content">
							<Collapsible open={expanded} trigger={<CollapsibleTrigger title={title} />}>
								<div className="columns">{children}</div>
							</Collapsible>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default CollapsibleContainer
