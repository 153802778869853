import React from 'react'
import './Hero.css'

const Hero = ({ image, title }) => {
	return (
		<React.Fragment>
			<div className="heroComponent" style={{ backgroundImage: `url(${image.url})` }}>
				<div className="container">
					<div className="columns">
						<div className="column">
							<div className="content">
								<h1 className="title">{title}</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default Hero
