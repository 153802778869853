import React from 'react'
import './About.css'
import { CollapsibleContainer } from '../../components'

const About = ({ text }) => {
	const renderAbout = () => {
		return (
			<React.Fragment>
				<div className="column is-full is-full-mobile" dangerouslySetInnerHTML={{ __html: text }} />
				<div className="column is-full is-full-mobile">
					<div className="columns">
						<div className="box box1 column is-one-third-desktop is-full-mobile">
							<div className="content">
								<h3 className="title">10+</h3>
								<h6 className="subtitle">Years of service</h6>
							</div>
						</div>
						<div className="box box2 column is-one-third-desktop is-full-mobile">
							<div className="content">
								<h3 className="title">300+</h3>
								<h6 className="subtitle">Projects implemented</h6>
							</div>
						</div>
						<div className="box box3 column is-one-third-desktop is-full-mobile">
							<div className="content">
								<h3 className="title">30+</h3>
								<h6 className="subtitle">Aircrafts</h6>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}

	return (
		<section name="aboutSection">
			<div className="aboutComponent">
				<CollapsibleContainer title="About">{renderAbout()}</CollapsibleContainer>
			</div>
		</section>
	)
}

export default About
