import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './Feed.css'
import { CollapsibleContainer } from '../../components'
import apiKey from './graphApiKey'

const Feed = () => {
	const [posts, setPosts] = useState([])

	const fetchData = async () => {
		const res = await axios(
			`https://graph.facebook.com/896780537001481/posts?access_token=${apiKey}&fields=permalink_url&limit=10`,
		)
		setPosts(res.data.data)
		window.FB.XFBML.parse(document.querySelector('[name=feedSection]'))
	}

	useEffect(() => {
		fetchData()
	}, [])

	const renderPosts = () => (
		<React.Fragment>
			{posts.map(post => (
				<div className="column is-half-desktop is-half-tablet is-full-mobile" key={post.id}>
					<div className="fb-post" data-show-text={true} data-href={post.permalink_url} key={post.id} />
				</div>
			))}
		</React.Fragment>
	)

	return (
		<section name="feedSection">
			<div className="feedComponent">
				<CollapsibleContainer title="News feed">{posts.length > 0 && renderPosts()}</CollapsibleContainer>
			</div>
		</section>
	)
}

export default Feed
