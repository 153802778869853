import React from 'react'
import './Footer.css'

const Footer = ({ locations }) => {
	const renderFooterLocations = () => {
		return locations.map(location => {
			const {
				location_address,
				location_address_link,
				location_aog,
				location_email,
				location_googlemaps_link,
				location_phone,
				location_title,
			} = location.primary
			return (
				<div className="content" key={location.id}>
					<div className="info">
						<span className="pageName">{location_title.text}</span>
						<a href={location_address_link.url} target={location_address_link.target}>
							{location_address.text}
						</a>
						<div className="mapWrap">
							<iframe
								src={location_googlemaps_link.url}
								width="100%"
								height="100%"
								frameBorder="0"
								style={{ border: 0 }}
								allowFullScreen
							/>
						</div>
					</div>
					<div className="links">
						<span>
							<a href={`tel:+354${location_phone.text}`}>
								Sími: {location_phone.text.substring(0, 3)}-{location_phone.text.substring(3)}
							</a>
						</span>
						<span>
							<a href={`tel:+354${location_aog.text}`}>
								AOG: {location_aog.text.substring(0, 3)}-{location_aog.text.substring(3)}
							</a>
						</span>
						<span>
							<a href={`mailto:${location_email.text}`}>{location_email.text}</a>
						</span>
					</div>
				</div>
			)
		})
	}
	return (
		<React.Fragment>
			<footer className="footerComponent">
				<div className="container">
					<div className="columns">
						<div className="column">{renderFooterLocations()}</div>
					</div>
				</div>
			</footer>
		</React.Fragment>
	)
}

export default Footer
