import React from 'react'
import './Employees.css'
import Employee from './Employee/employee.component'
import { CollapsibleContainer } from '../../components'

const Employees = ({ employees }) => {
	const renderEmployees = () => {
		return employees.map(employee => {
			const { data } = employee.node
			const formattedEmployee = {
				name: data.name.text,
				title: data.title.text,
				email: data.email.text,
			}
			return (
				<div className="column is-one-third-desktop is-half-tablet is-full-mobile" key={employee.node.id}>
					<Employee employee={formattedEmployee} key={employee.node.id} />
				</div>
			)
		})
	}

	return (
		<section name="employeeSection">
			<div className="employeesComponent">
				<CollapsibleContainer title="Employees" expanded={false}>
					{renderEmployees()}
				</CollapsibleContainer>
			</div>
		</section>
	)
}

export default Employees
