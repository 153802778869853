import React, { useEffect, useState } from 'react'
import { Link } from 'react-scroll'

const HeaderLink = ({ link, toggleBurger }) => {
	const [vw, setVW] = useState(0)
	useEffect(() => {
		setVW(window.innerWidth)
	})

	const handleClick = (e, section) => {
		toggleBurger()

		const sectionElement = document.querySelector(`[name=${section}] .Collapsible__trigger.is-closed`)
		if (sectionElement) {
			sectionElement.click()
		}

		const target = e.target
		setTimeout(() => {
			const navbarItems = document.querySelectorAll('.navbar-item.active')
			for (let i = 0; i < navbarItems.length; i += 1) {
				if (navbarItems[i] != target) {
					navbarItems[i].classList.remove('active')
				}
			}
			target.classList.add('active')
		}, 375)
	}

	return (
		<Link
			className="navbar-item"
			to={link.href}
			smooth
			spy
			isDynamic
			onClick={e => handleClick(e, link.href)}
			offset={vw > 1087 ? -70 : 0}
			duration={300}
		>
			<span>{link.title}</span>
		</Link>
	)
}

export default HeaderLink
