import React, { useState } from 'react'
import './collapsibleTrigger.css'
import { ExpandMore, ExpandLess } from 'styled-icons/material'

const CollapsibleTrigger = ({ title }) => {
	const [expanded, toggleExpanded] = useState(true)

	return (
		<React.Fragment>
			<div className="collapsibleTriggerComponent">
				<div className="titleRow" onClick={() => toggleExpanded(!expanded)}>
					<h3>{title}</h3>
					{!expanded && <ExpandMore />}
					{expanded && <ExpandLess />}
				</div>
			</div>
		</React.Fragment>
	)
}

export default CollapsibleTrigger
