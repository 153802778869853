import 'babel-polyfill'
import React from 'react'
import { graphql } from 'gatsby'
import * as Containers from '../containers'
import '../css/app.css'
import { Helmet } from 'react-helmet'

export default ({ data }) => {
	const { data: homepage } = data.prismicHomepage
	const { Header, Hero, About, Employees, Customers, CapabilityList, Feed, Footer } = Containers
	return (
		<React.Fragment>
			<Helmet>
				<title>{homepage.seo_title.text}</title>
				<meta name="author" content="Orri Arnarsson" />
				<meta name="description" content={homepage.seo_description.text} />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta property="og:image" content={homepage.seo_image.url} />
				<meta property="og:image:alt" content="Arctic Maintenance Logo" />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta property="og:title" content={homepage.seo_title.text} />
				<meta property="og:description" content={homepage.seo_description.text} />
				<meta property="og:site_name" content={homepage.seo_title.text} />
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://arcticm.is" />
				<link rel="canonical" href="https://arcticm.is" />
				<link
					rel="stylesheet"
					href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900|Raleway:300,400,600,700"
				/>
				<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.2/css/bulma.min.css" />
				<html lang="is" />
				<script type="text/javascript">{`
				(function(d, s, id) {
					var js, fjs = d.getElementsByTagName(s)[0];
					if (d.getElementById(id)) return;
					js = d.createElement(s); js.id = id;
					js.src = 'https://connect.facebook.net/is_IS/sdk.js#xfbml=1&version=v3.2&appId=552523225261267&autoLogAppEvents=1';
					fjs.parentNode.insertBefore(js, fjs);
				}(document, 'script', 'facebook-jssdk'));
				`}</script>
				<div id="fb-root" />
			</Helmet>
			<Header />
			<Hero image={homepage.hero_image} title={homepage.hero_title.text} />
			<CapabilityList capabilities={data.allPrismicCapability.edges} />
			<About text={homepage.about_text.html} />
			<Feed />
			<Employees employees={data.allPrismicEmployee.edges} />
			<Customers customers={data.allPrismicCustomer.edges} />
			<Footer locations={homepage.body} />
		</React.Fragment>
	)
}

export const query = graphql`
	query ArcticMaintenance {
		prismicHomepage {
			data {
				body {
					... on PrismicHomepageBodyFooterLocation {
						id
						primary {
							location_title {
								text
							}
							location_address {
								text
							}
							location_address_link {
								url
								target
							}
							location_phone {
								text
							}
							location_aog {
								text
							}
							location_email {
								text
							}
							location_googlemaps_link {
								url
							}
						}
					}
				}
				hero_title {
					text
				}
				hero_image {
					url
				}
				about_text {
					html
				}
				seo_title {
					text
				}
				seo_description {
					text
				}
				seo_image {
					url
				}
			}
		}
		allPrismicCapability(sort: { fields: data___title___text }) {
			edges {
				node {
					id
					data {
						title {
							text
						}
						image {
							url
						}
						link {
							url
							target
						}
					}
				}
			}
		}
		allPrismicEmployee(sort: { fields: data___name___text }) {
			edges {
				node {
					id
					data {
						name {
							text
						}
						title {
							text
						}
						email {
							text
						}
					}
				}
			}
		}
		allPrismicCustomer(sort: { fields: data___name___text }) {
			edges {
				node {
					id
					data {
						name {
							text
						}
						logo {
							alt
							url
						}
						link {
							url
							target
						}
					}
				}
			}
		}
	}
`
