import React from 'react'
import './CapabilityList.css'
import { CollapsibleContainer } from '../../components'

const CapabilityList = ({ capabilities }) => {
	const renderVehicles = () => {
		return capabilities.map(item => {
			let { title, link, image } = item.node.data
			if (!link) link = { url: '', target: '' }
			return (
				<div className="column is-one-third-desktop is-half-tablet is-full-mobile" key={item.node.id}>
					<a
						href={link.url}
						target={link.target}
						style={{ backgroundImage: `url(${image.url})` }}
						className="vehicleComponent"
					>
						<div className="vehicleTitle">{title.text}</div>
					</a>
				</div>
			)
		})
	}

	return (
		<section name="capabilitySection">
			<div className="capabilityListComponent">
				<CollapsibleContainer title="Capability list">{renderVehicles()}</CollapsibleContainer>
			</div>
		</section>
	)
}

export default CapabilityList
